
import { getCookie } from "../../services/Common";

export const getMappedTeamData = (userEmail) =>{
 
    try{
        let obj = JSON.parse(getCookie('teamMembers'))
        return obj[userEmail].first_name

    }catch(e){
        return ""
    }
 
  }